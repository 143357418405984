import { Message } from "element-ui";
import {REG_TEL} from "~/utils/consts";
import {SUCCESS} from "~/utils/status-code";
import {followUser} from "../utils/api";
import {throttle} from "lodash";

/**
 * 存储 localStorage.
 *
 * @param name
 * @param content
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }

  window.localStorage.setItem(name, content);
};

/**
 * 从 localStorage 中获取数据.
 *
 * @param name
 * @returns {*}
 */
export const getStore = (name) => {
  let data = window.localStorage.getItem(name);

  if (!data) {
    return null;
  }

  return JSON.parse(data);
};


/**
 * 返回 13 位时间戳.
 *
 * @param datestr
 * @returns {number}
 */
export const getTimestamp = (datestr) => {
  datestr = datestr.replace(/-/g, "/");
  let date = new Date(datestr);
  return parseInt(date.getTime());
};

/**
 * 获取当前时间差.
 *
 * @param datestr
 * @param now 时间差基准时间戳，秒
 * @returns {*}
 */
export const getTimeDifferenceByNow = (datestr, now)  => {
  now = now ? now * 1000 : null;
  let ms = (now || (new Date()).getTime()) - getTimestamp(datestr);

  let m = ms / 1000 / 60 + 0.0001; // 最小1分钟，+ 0.0001 避免出现 0
  if (m < 60) {
    return Math.ceil(m) + '分钟';
  }

  let h = m / 60;
  if (h < 24) {
    return parseInt(h) + '小时';
  }

  let d = h / 24;
  if (d < 30) {
    return parseInt(d) + "天";
  }

  let M = d / 30;
  if (M < 12) {
    return parseInt(M) + "月"
  }

  return parseInt(M / 12) + "年";
};

/**
 * 从路径中获取文章标题.
 *
 * @param path
 * @returns {string}
 */
export const getTitleFromPath = (path) => {
  let strArr = path.split('/');
  let title = strArr[strArr.length - 1];
  let i = title.lastIndexOf('.');
  title = i !== -1 ? title.substring(0, i) : title;

  return title;
};

/**
 * 从路径中获取扩展名.
 *
 * @param path
 * @returns {string}
 */
export const getExtensionFromPath = (path) => {
  let strArr = path.split('/');
  let title = strArr[strArr.length - 1];
  let i = title.lastIndexOf('.');
  title = i !== -1 ? title.substring(i + 1) : title;

  return title;
};

/**
 * 输出 error 消息.
 *
 * @param msg
 */
export const errorMsg = (msg) => {
  printMsg(msg, 'error', true);
};

/**
 * default error handler: log to console
 *
 * @param err
 */
export const logError = (err) => {
  console.error(err);
}

/**
 * 输出 info 信息.
 *
 * @param msg
 */
export const infoMsg = (msg) => {
  printMsg(msg, 'info', true);
};

/**
 * 输出 success 消息.
 *
 * @param msg
 */
export const successMsg = (msg) => {
  printMsg(msg, 'success', true);
};

/**
 * 输出信息.
 *
 * @param msg
 * @param type
 * @param close
 */
export const printMsg = (msg, type, close) => {
  console.log(type, msg, close);
  Message({
    showClose: close,
    type: type,
    message: msg,
    // duration: 3000,
  });
};

/**
 * 判断数据是否为空.
 *
 * @param dataList 数据数组，格式为: [{data: <data>, msg: <msg>}, ...]
 */
export const checkDataEmpty = (dataList) => {
  for(let item of dataList) {
    if (!item.data) {
      errorMsg(item.msg);
      return false;
    }
  }

  return true
};

/**
 * 获取当前时间.
 *
 * @returns {*}
 */
export const getDateStr = () => {
  Date.prototype.format = function (fmt) {
    var o = {
      "M+": this.getMonth() + 1, //月份
      "d+": this.getDate(), //日
      "h+": this.getHours(), //小时
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  };

  return new Date().format("yyyy-MM-dd hh:mm:ss");
};

export const parseList = (list) => {
  var map = {};

  // 由于对象是引用传递，所以这里list 和 map 实际上指向相同的数据.
  list.forEach(item => {
    if (! map[item.id]) {
      map[item.id] = item;
    }
  });

  list.forEach(item => {
    if (item.comment_id != "0") {
      map[item.comment_id].children ? map[item.comment_id].children.push(item) : map[item.comment_id].children = [item];
    }
  });

  return list.filter(item => {
    if (item.comment_id == "0") {
      return item;
    }
  });
};

export const parseListForMobile = (list) => {
  var map = {};

  for (let i = 0; i < list.length; i++) {
    if (!list[i].comment_id || list[i].comment_id == "0") {
      continue;
    }

    let comment_id = list[i].comment_id;

    for (let j = 0; j < list.length; j++) {
      if (list[j].id == comment_id) {
        list[i].showNick = list[i].nickname + "回复" + list[j].nickname;
      }
    }
  }

  list.forEach(item => {
    if (! map[item.id]) {
      map[item.id] = item;
    }
  });

  list.forEach(item => {
    if (item.source_id != "0") {
      map[item.source_id].children ? map[item.source_id].children.push(item) : map[item.source_id].children = [item];
    }
  });

  return list.filter(item => {
    if (item.source_id == "0") {
      return item;
    }
  });
};

/**
 * 检查用户是否登录，如果已经登录，则跳转到首页.
 *
 * @param store
 * @param router
 */
export const checkLogin = (store, router) => {
  if (store.state.auth.isLogin) {
    successMsg("您已经登录，即将跳转到首页");
    setTimeout(() => {
      router.push("/");
    }, 1000);
  }
};

export const checkNotLogin = (isLogin, router) => {
  if (!isLogin) {
    $nuxt.$store.commit("auth/SHOW_LOGIN", true);
  }
}

/**
 * 根据 parent（大小）、mode 等比例缩放 img，并调整 img 在 parent 中的显示位置（居中显示）
 *
 * @param img
 * @param container
 * @param mode 可取值：fill (default), full (完整显示)
*/
export function scaleImg(img, container, mode = "fill") {
  if (img && !img.__uninote_scaled__) {
    img.__uninote_scaled__ = true;

    if (img.naturalWidth) {
      // already loaded
      __doscale(img, container, mode);
    } else {
      // add load listener
      img.addEventListener("load", (e) => {
        __doscale(e.target, container, mode);
      });
    }
  }

  function __doscale(img, container, mode) {
    img.style.position = "relative";
    container = container || img.parentElement; // 默认 container 为 parent element
    let cwdith = container.clientWidth;
    let cheight = container.clientHeight;
    let cratio = cwdith / cheight;
    let iwdith = img.naturalWidth;
    let iheight = img.naturalHeight;
    let iratio = iwdith / iheight;

    if (mode === "fill") {
      if (cratio >= iratio) {
        let newHeight = cwdith * iheight / iwdith;
        img.style.width = cwdith + "px";
        img.style.height = newHeight + "px";
        img.style.top = (cheight - newHeight) / 2 + "px";
      } else {
        let newWdith = iwdith * cheight / iheight;
        img.style.width = newWdith + "px";
        img.style.heigth = cheight + "px";
        img.style.left = (cwdith - newWdith) / 2 + "px";
      }
    } else if (mode === "full") {
      if (cratio >= iratio) {
        let newWdith = iwdith * cheight / iheight;
        img.style.width = newWdith + "px";
        img.style.heigth = cheight + "px";
        img.style.padding = "0 " + (cwdith - newWdith) / 2 + "px";
      } else {
        let newHeight = cwdith * iheight / iwdith;
        img.style.width = cwdith + "px";
        img.style.heigth = newHeight + "px";
        img.style.padding = (cheight - newHeight) / 2 + "px 0";
      }
    } else {
      throw "unknown scale mode";
    }
  }
}

/**
 * 封装 el-form 的验证，返回 promise
 */
export const elformValidate = (form, fields) => {
  function syncValidate(form, field) {
    let p = new Promise((resolve, reject) => {
      form.validateField(field, err => {
        if (err) {
          reject(err);
        } else {
          resolve(err);
        }
      });
    });
    return p;
  }

  let promises = [];
  fields.forEach((field) => {
    promises.push(syncValidate(form, field));
  })
  return Promise.all(promises);
}

/**
 * 关注/取关用户
 *
 * 封装调用 api 发送请求
 */
export const followUserWrapper = (users, index, status) => {
  let user = users[index];
  followUser(user.id, status).then((res) => {
    successMsg(res.data.msg);
    user.follow = res.data.bool ? 1 : 0;
  });
}

/**
 * 处理搜索接口返回的数据，以用于 article-list.vue
 *
 * @param article
 * @returns {Array}
 */
export const processArticles = (article) => {
  // 文章数据处理
  let resultArts = [];
  if (article) {
    // 循环每篇文章
    article.forEach(art => {
      // 如果搜出来的文章没有对应的信息(_artinfo === [])，则证明是 es 中的垃圾数据，忽略掉即可
      if (!Array.isArray(art._artinfo)) {
        let item = Object.assign(art._artinfo, art._source);
        // title 高亮
        item.title = (art.highlight['title'] || art.highlight['title.pinyin'] || [item.title])[0];
        // 内容高亮
        item.abstract = "";
        let arr = art.highlight['content'] || art.highlight['content.pinyin'];
        if (arr) {
          // 逐行处理，最多展示两条
          let count = Math.min(arr.length, 2);
          for (let i = 0; i < count; i++) {
            // sln 1:
            // let oneBlock = arr[i].trim().replace(/\n+/g, " ↩ ");
            // item.abstract +=  oneBlock + "...<br>\n";
            // sln 2:
            let oneBlock = arr[i].trim().replace(/\n+/g, "<br>") + "...";
            item.abstract +=  (i > 0 ? "<br><br>\n" : "") + oneBlock;
          }
        } else {
          item.abstract = art._source.abstract
        }
        resultArts.push(item);
      }
    });
  }
  return resultArts;
}

/**
 * beforeDestroy 时自动取消事件注册
 *
 * @param type string DOM event type
 * @param component Vue component
 * @param method function Vue component's method
 */
export const domEvent = (type, component, method) => {
  window.addEventListener(type, method);
  component.$options.beforeDestroy.push(() => {
    window.removeEventListener(type, method);
  });
};

/**
 * beforeDestroy 时自动取消事件注册
 *
 * @param component Vue component
 * @param minWait int min wait time (in ms) before next loading
 * @param oneLoading boolean allow only one loading at a time or allow concurrent loading or
 * field name in vue.$data，方便上层控制显示动画
 * @param callback function do the real things, ** should ** return a Promise
 * @return loader function 可用于强制 load：loader(null, force)
 */
export const scrollLoad = (component, minWait, oneLoading, callback) => {
  let throttled = throttle(callback, minWait);
  let loading = false;
  let isLoading = () => {
    if (oneLoading) {
      if (typeof(oneLoading) === "string") {
        return component.$data[oneLoading];
      } else {
        return loading;
      }
    }
  };
  let setLoading = (val) => {
    if (oneLoading && typeof(oneLoading) === "string") {
      component.$data[oneLoading] = val;
    } else {
      loading = val;
    }
  };
  let loader = (e, force) => {
    if (!force && isLoading()) {
      // console.log('loading, return');
      return;
    }

    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    let clientHeight = window.innerHeight || Math.min(document.documentElement.clientHeight,document.body.clientHeight);

    if(force || clientHeight + scrollTop >= scrollHeight){
      // console.log('loading');
      setLoading(true);
      let p = throttled();
      if (p instanceof Promise) {
        p.finally(() => {
          // console.log('done');
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    }
  };
  domEvent('scroll', component, loader);
  return loader;
};

export const setClipboard = (data) => {
  if (!data) {
    errorMsg('复制内容不能为空');
    return;
  }
  const textareaEl = document.createElement('textarea');
  textareaEl.setAttribute('readonly', 'readonly');
  textareaEl.value = data;
  document.body.appendChild(textareaEl);
  textareaEl.select();
  const res = document.execCommand('copy');
  document.body.removeChild(textareaEl);
  // successMsg('复制成功');
  return res;
};

export const uuid = (len, radix) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
};

/**
 *  判断指定元素在数组中出现的次数
 *
 * @param source Array
 * @param target 目标元素
 * @return count 目标元素在数组中出现的总次数
 */
 export const findCountInArr = (source, target) => {
  let count = 0;
  source.forEach((v) => {
    count += v === target ? 1: 0;
  });
  return count;
};
/**
 *  将对象数组按照指定key进行深度拍平处理
 *
 * @param source Array
 * @param key 将要被拍平的key
 * @return [] 根据
 */
 export const flatObjArr = (source = [], key) => {
  let temp = [];
   function flat(source) {
     source.forEach((sourceItem) => {
       if (sourceItem.type === 'file') {
         temp = [...temp, {...sourceItem}];
       }
       if (Array.isArray(sourceItem[key]) && sourceItem[key].length) {
         flat(sourceItem[key]);
       }
     });
     return temp;
   }
  return flat(source);
 }

/**
 * 聚焦到 el-form 表单的第一个验证错误的 input field
 *
 * 注意，仅针对 element-ui form
 *
 * @returns {boolean} true on errors, false otherwise
 */
export const focus1stError = () => {
  const isError = document.getElementsByClassName('is-error');
  if (isError.length > 0) {
    isError[0].querySelector('input').focus();
    return true;
  }
  return false;
};

export const device = (() => {
  let device = 'web';
  if (process.client) {
    if((window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|UCBrowser)/i))) {
      device = "mobile";
    }
  }
  return device;
})();

/**
 * 根据路径获取文件名
 *
 * @param path string path of resource
 * @param sep "/" or "\"
 * @returns {string} file name
 */
export const getFileName = (path, sep = "/") => {
  if (!path) {
    return "";
  }
  if (sep !== "/" && sep !== "\\") {
    throw "sep must be '/' or '\\'";
  }

  const index = path.lastIndexOf(sep);
  if (sep !== -1) {
    return path.substring(index + 1, path.length);
  } else {
    return path;
  }
};

/**
 * 根据路径获取文件夹名
 *
 * @param path string path of resource
 * @param sep "/" or "\"
 * @returns {string} dir name
 */
export const getDirName = (path, sep = "/") => {
  if (!path) {
    return "";
  }
  if (sep !== "/" && sep !== "\\") {
    throw "sep must be '/' or '\\'";
  }

  const index = path.lastIndexOf(sep);
  if (sep !== -1) {
    return path.substring(0, index);
  } else {
    return "";
  }
};

/**
 * 动态加载js
 *
 * @param url
 * @returns {Promise<any>}
 */
export const fetchScript = (url) => {
  const scriptjs = require('scriptjs');
  return new Promise((resolve) => {
    scriptjs(url, () => {
      resolve();
    })
  });
};
