<template>
  <div class="main">
    <el-dialog
      width="870px"
      :visible="showLogin"
      @close="handleHasOpen"
      :close-on-click-modal="false"
    >
      <el-row style="height: 600px;" class="content-box">
        <el-col :span="12" class="left" style="height: 100%">
          <div style="text-align: center; height: 80%; margin-top:60px" class="left-content">
            <nuxt-link to="/">
              <img src="~/assets/img/big-icon.png" alt="首页" style="display: inline-block; margin-top: 154px;"/>
            </nuxt-link>
            <h2 class="title">Uninote</h2>
          </div>
        </el-col>
        <el-col :span="12" class="right">
          <div class="right-content">
            <h2 class="title" style="margin-top: 80px">登录</h2>
            <el-form :model="ruleForm" @keydown.native.enter.prevent="login" :rules="rules" ref="ruleFormRef">
              <el-form-item prop="tel">
                <el-input v-model="ruleForm.tel" ref="tel" maxlength="11" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="ruleForm.password" type="password" autocomplete="on" placeholder="请输入密码"></el-input>
              </el-form-item>
              <p class="link" style="text-align: right">
                <nuxt-link to="/user/reset_pwd">忘记密码</nuxt-link>
              </p>
              <!--submit button-->
              <el-form-item style="margin-top: 40px">
                <el-button type="primary" @click="login" style="width: 100%; margin-top: 20px">登录</el-button>
              </el-form-item>
              <el-form-item style="text-align: center; border-bottom: none">
                <nuxt-link to="/" class="link">返回首页</nuxt-link>
                <span>|</span>
                <nuxt-link to="/user/register" class="link">账号注册</nuxt-link>
              </el-form-item>
            </el-form>

            <div class="third-login">
              <p class="third-title">
                <span>更多登录方式</span>
              </p>
              <p class="third-list">
                                <span>
                                    <el-button type="text" @click="thirdLogin"><img
                                      src="~/assets/img/wechat.png"/></el-button>
                                </span>
                <span>
                                    <el-button type="text" @click="thirdLogin"><img
                                      src="~/assets/img/qq.png"/></el-button>
                                </span>
                <span>
                                    <el-button type="text" @click="thirdLogin"><img src="~/assets/img/weboo.png"
                                                                                    alt=""/></el-button>
                                </span>
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import {infoMsg, errorMsg, successMsg, focus1stError} from '~/utils/tools'
  import {SUCCESS} from '~/utils/status-code'
  import {REG_TEL} from '~/utils/consts'
  import {mapMutations, mapState} from 'vuex'
  import * as apis from '~/utils/api'

  export default {
    name: 'login',

    data() {
      return {
        ruleForm: {
          tel: '',
          password: '',
        },
        preHtmlAttr: '',
        rules: {
          tel: {
            required: true,
            type: 'string',
            pattern: REG_TEL,
            message: '手机号格式不正确',
            trigger: 'blur',
          },
          password: {
            required: true,
            type: 'string',
            pattern: /^.{6,}$/,
            message: '密码至少6位',
            trigger: 'blur',
          },
        },
        loading: false
      }
    },

    async asyncData({store}) {
      await store.dispatch('auth/getUserInfo')
    },
    watch: {
      $route(to) {
        // 导航到任意地址都关闭登录框
        this.handleHasOpen();
      },
      showLogin(val) {
        this.$nextTick(() => {
          val && this.$refs.tel.focus();
        });
      },
    },
    computed: {
      ...mapState('auth', [
        'showLogin',
      ]),
    },
    methods: {
      ...mapMutations({
        RECORD_USERINFO: 'auth/RECORD_USERINFO',
      }),
      async login() {
        if (this.loading) return;
        this.$refs['ruleFormRef'].validate(async valid => {
          if (valid) {
            this.loading = true;
            apis.login(this.ruleForm.tel, this.ruleForm.password).then(res => {
              if (res.errno !== SUCCESS.code) {
                errorMsg(res.errmsg)
              } else {
                this.RECORD_USERINFO(res.data)
                this.handleHasOpen();
                successMsg('登录成功')
                // setTimeout(() => {
                // 通过 router 的 go 方法进行跳转
                // 避免跳转到 blink 页面。blank 页面也算一个 history，因此这里是2
                //     if (window.history.length > 2) {
                //         this.$router.go(-1)
                //     } else {
                //         this.$router.push('/')
                //     }
                // }, 1000)
              }
            }).finally(() => {
              this.loading = false;
            });
          }
          else {
            focus1stError();
          }
        })
      },
      /**
       * 登录弹框
       */
      handleHasOpen() {
        this.$store.commit("auth/SHOW_LOGIN", false)
      },
      /**
       * 第三方登录
       */
      thirdLogin() {
        infoMsg('暂不支持第三方登录，敬请期待~')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import "~assets/stylus/variable.styl";
  @import "~assets/stylus/user.styl";

  .main {
    /deep/ .el-dialog {
      background-color transparent
      box-shadow: none;
    }
    /deep/ .el-dialog__wrapper {
      overflow hidden
    }
    /deep/ .el-dialog__header {
      padding: 0;
    }
    /deep/ .el-dialog__headerbtn {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index 2002
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      .el-dialog__close {
        font-size: 42px;
        color: #f5f5f5;
      }
    }
    .content-box {
      width: 870px;
      height 600px;
      background url("~assets/img/login_box_bg.png") center no-repeat
      flex: none;

      .title {
        text-align center
        font-size 28px
        font-weight bold
        color: base_color
      }

      .left-content {
        border-right: solid 1px border_color_lv3;
        margin-left: 81px;
      }

      .right-content {
        .third-login {
          // margin-top: 65px;
          margin-top: 50px;
          .third-title {
            border-bottom: 1px solid border_color_lv3;
            text-align: center;
            span {
              position: relative;
              bottom: -9px;
              font-size: 14px;
              background: #FFF;
              padding: 0 30px;
              color minor_text_color
            }
          }
        }
        .third-list {
          text-align center
          padding-top 10px
          span {
            display inline-block
            width 30%
            text-align: center
          }
        }
      }

    }

    @media (max-width: 735px) {
      .content-box {
        width: 300px;

        .left {
          display: none;
        }

        .right {
          width: 100%
        }
      }
    }
  }
</style>
