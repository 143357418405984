/* ------------------------------------------------ regexp ------------------------------------------------*/
// 手机号
export const REG_TEL = /^(0|86|17951)?1[0-9]{10}$/;
// 昵称
export const REG_NICK = /^[a-zA-Z0-9_\u4e00-\u9fa5]{2,20}$/;
// 电子邮件
export const REG_EMAIL = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/; // ^[a-z0-9]+([._\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$

/* ------------------------------------------------ msg ------------------------------------------------*/
export const MSG_ERR_NICK = "昵称为2-20个字符，只能包含字母、中文、数字、下划线";
