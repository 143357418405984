import qs from 'qs';
import { USER_NOT_LOGIN } from '~/utils/status-code';
import { errorMsg } from '~/utils/tools';
import { setAxios } from '~/utils/api';
import { UPLOAD_TPYE } from '~/config/constant';

export default function ({ $axios, redirect, store }) {

  setAxios($axios);
  $axios.defaults.timeout = 30000; // 默认超时时间

  $axios.onRequest(config => {
    if (config.method === 'post') {
      if (config.requestType !== UPLOAD_TPYE) {
        config.data = qs.stringify(config.data);
      }
    }
  });

  $axios.onResponse(res => {
  });

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status);
    console.log('ERROR: ', code);
  });
}
