/**
 * 请求成功.
 *
 * @type {{msg: string, code: number}}
 */
export const SUCCESS = {
  code: 200,
  msg: "OKAY"
};

/**
 * 用户名或密码不正确.
 *
 * @type {{msg: string, code: number}}
 */
export const USERNAME_OR_PASSWORD_ERROR = {
  code: -1103,
  msg: "用户名或密码不正确"
};

/**
 * 用户还未登录
 * @type {{msg: string, code: number}}
 */
export const USER_NOT_LOGIN = {
  code: -1100,
  msg: "用户还未登录"
};

/**
 * 邮箱验证码已经发送.
 *
 * @type {{msg: string, code: number}}
 */
export const EMAIL_IS_SEND = {
  code: -1016,
  msg: "邮箱验证码已经发送，请稍后再试"
};

/**
 * 缺少参数.
 *
 * @type {{msg: string, code: number}}
 */
export const LOST_PARAMETER = {
  code: -1101,
  msg: "缺少参数"
};

export const MERGE_BRANCH_FAILED = {
  code: -1414,
  msg: "合并分支失败"
};
