<style lang="stylus" scoped>
  @import '~assets/stylus/header.styl'
</style>
<style lang="stylus">
  /* el-dropdown-menu dropdown 部分挂载到了 body 下，因此需要非 scoped */
  .avatar-dd {
    width: 100px;

    li {
      /* 增减可点击范围 */
      padding 0;
      /* 修改 padding 后需要修改分隔条 */
      &.el-dropdown-menu__item--divided:before {
        content: unset
      }
    }
    a {
      display: inline-block;
      width: 100%;
      padding: 0 20px;
    }
  }
</style>

<template>
  <div>
    <header class="v-header" v-show="isShow">
      <div class="header-container">
        <nuxt-link to="/">
          <img src="~/assets/img/logo.png" alt="Uninote" class="logo-img">
        </nuxt-link>
        <nav class="main-nav">
          <ul class="nav-list">
            <li class="main-item">
              <v-dropdown />
            </li>
            <li class="search">
              <v-input v-model="search_keyword" @search="search" />
            </li>
            <li class="write">
              <el-button type="primary" size="small" @click="edit">写文章</el-button>
            </li>
            <li class="write">
              <el-button type="primary" size="small" @click="showImportDialog">导入文章</el-button>
            </li>
            <li class="book">
              <nuxt-link :to="{ name: 'book-uid-detail', params: {uid: this.userInfo.id} }">
                <img src="~/assets/img/book.png" alt="book" v-if="! book_on">
                <img src="~/assets/img/book-on.png" alt="book" v-else>
              </nuxt-link>
            </li>
            <!--
            <li>
              <nuxt-link to="/">
                <img src="~/assets/img/tongzhi.png" alt="message">
              </nuxt-link>
            </li>
            -->
            <li class="auth">
              <!-- 如果未登录 -->
              <span id="id-login" class="login" v-if="!isLogin" @click="handleHasOpen()">登录</span>

              <!-- 已经登录 -->
              <el-dropdown v-if="isLogin">
              <span class="avatar">
                <img :src="userInfo.pic" v-if="userInfo.pic" height="30" width="30" />
                <img src="~/assets/img/default_headimg.png" width="30" height="30" v-else />
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu class="avatar-dd" slot="dropdown">
                  <el-dropdown-item>
                    <nuxt-link to="/user/setting">用户中心</nuxt-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <nuxt-link :to="{ name: 'book-uid-detail', params: {uid: this.userInfo.id} }">个人主页</nuxt-link>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <a id="id-logout" @click="logout">退出</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <el-dialog title="导入流程（仅掘金）" :visible.sync="isShowImportDialog" :close-on-click-modal="true">
      <ul style="padding: 0 20px">
        <li>1、点击下面的“获取导入代码”按钮，导入代码将复制到剪贴板</li>
        <li>2、在浏览器中打开<a href="https://juejin.im" class="normalA" target="_blank">掘金</a>，用自己的掘金账号、密码登录</li>
        <li>3、打开浏览器中的调试器，进入“Console”标签，粘贴，然后回车，等待导入完成</li>
        <li>4、等待导入完成后，回到<a href="/book" class="normalA" target="_blank">个人文库</a>，查看导入结果</li>
      </ul>
      <br>
      <el-button type="primary" @click="importArticle">复制导入代码</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import VDropdown from './dropdown';
  import { mapState } from 'vuex';
  import VInput from '~/components/input';
  import {setClipboard, successMsg, errorMsg} from "~/utils/tools";
  import * as apis from "~/utils/api";

  export default {
    name: 'VHeader',
    props: [
      'book_on',
    ],
    components: {
      VDropdown,
      VInput
    },
    mounted() {
      // search_keyword 处理（清空与恢复），另见 watch $route
      this.search_keyword = this.$route.query.key;

      if (document.body.clientWidth <= 650) {
        let oldTop = 0;
        window.onscroll = () => {
          let top = document.scrollingElement.scrollTop;
          if (top > 50) {
            this.isShow = !(oldTop < top);
          }
          oldTop = top;
        }
      }
    },
    data () {
      return {
        search_keyword: '',    // 搜索关键字
        isShow: true,
        isShowImportDialog: false,
      }
    },
    computed: {
      ...mapState({
      }),
      ...mapState('auth', [
        'isLogin',
        'userInfo'
      ])
    },
    watch: {
      $route() {
        this.isShow = true;
        // 进入搜索页时恢复 search_keyword，其他页面清空
        if (this.$route.name !== "search") {
          this.search_keyword = "";
        } else {
          this.search_keyword = this.$route.query.key;
        }
      }
    },
    methods: {
      /**
       * 退出登录
       */
      logout() {
        this.$store.dispatch('auth/logout');
      },

      /**
       * 登录弹框
       */
      handleHasOpen() {
        this.$store.commit("auth/SHOW_LOGIN", true);
      },

      /**
       * 搜索
       */
      search(key) {
        if (key) {
          this.$router.push({
            name: "search",
            query: {key: key},
          });
        }
      },

      /**
       * 编辑
       */
      edit() {
        this.$router.push({
          name: "editor-id"
        });
      },

      showImportDialog() {
        this.isShowImportDialog = true;
      },

      /**
       * 导入文章
       */
      importArticle() {
        apis.getImportArtCode().then((res) => {
          console.log(res);
          if (setClipboard(res.data.code)) {
            successMsg("导入代码复制成功，可以去掘金导入了:）");
          } else {
            errorMsg("导入代码复制失败");
          }
        });
        this.isShowImportDialog = false;
      },
    }
  }
</script>
