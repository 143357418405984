/**
 * @Description: 针对元素当鼠标移入时，在鼠标移入瞬间的正上方位置出现工具条类目的东西，当鼠标移出时，隐藏掉，要求目标工具条元素的默认style.display为none
 * @Author: mahui
 * @Date: 2021/6/27 11:13 PM
 */
import Vue from "vue"
const adapterDistance = 30;
import { device } from "../utils/tools";

const moveShowTools = Vue.directive("mouse-m-s",{
  inserted:function(el,bindings) {
    if (device === 'mobile') return false;
    const { disabled, className } = bindings.value;
    if (disabled) return false;
    const tipEl = el.querySelector(`.${className}`);
    el.onmousemove = function(e) {
      if (tipEl.style.display === 'none') {
        const { top } = el.getBoundingClientRect();
        const { right: maxRight } = document.querySelector(".tree-box").getBoundingClientRect();
        const isMoreThanMaxRight = e.clientX - maxRight + adapterDistance >= 0;
        tipEl.style.position = 'fixed';
        tipEl.style.display = 'flex';
        tipEl.style.left = isMoreThanMaxRight ? (maxRight - adapterDistance + "px"): (e.clientX + adapterDistance + "px");
        tipEl.style.top = top + 30 + 'px';
        tipEl.style.overflow = 'visible';
        tipEl.style['white-space'] = 'nowrap';
        tipEl.style['z-index'] = '999999';
        tipEl.style.transform = 'translate(-10%, -100%)';
        tipEl.onmousemove = function() {
          tipEl.style.display = 'flex';
        }
      }
    };
    el.onmouseout = function(e) {
      if (el.contains(e.relatedTarget)) return false;
      tipEl.style.display = 'none';
    }
  }
})

export default moveShowTools
