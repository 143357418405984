import qs from 'qs';
import {SUCCESS, USER_NOT_LOGIN} from "./status-code";
import {errorMsg, logError} from "./tools";
import { UPLOAD_TPYE } from '~/config/constant';

let $axios = null;

export const setAxios = (a) => {
  $axios = a;
};

/**
 * 通用的 API 调用结果处理 helper
 * @param p promise to heandle
 * @param showErr boolean, show error or not, default true
 * @return {*|PromiseLike<T>|Promise<T>}
 */
function apiResult(p, showErr = true, url = '') {
  return p.then(res => {
    if (process.client) {
      $nuxt.$store.dispatch('common/setServerTime', res.stime);
    }
    if (res.errno === SUCCESS.code) {
      return res;
    }
    if (res.errno === USER_NOT_LOGIN.code) {
      if (process.client && url && ['/api/userinfo/get-my-info'].indexOf(url) === -1) {
        $nuxt.$store.commit("auth/SHOW_LOGIN", true);
        $nuxt.$store.dispatch('auth/logout');
      }
    }
    // 非 200 错误会传递到下面的 catch 统一处理
    return Promise.reject(res);
  }).catch(err => {
    if (err.errmsg) {
      // 非 200 错误
      if (showErr) {
        errorMsg(err.errmsg);
      }
    } else {
      // 其他错误（网络、400、500 ...）
      if (showErr) {
        errorMsg(err);
      }
    }
    return Promise.reject(err);
  });
}

/**
 * $axios.$post wrapper
 *
 * @param url
 * @param data
 * @param showErr
 * @param config
 * @return {*|PromiseLike<T>|Promise<T>}
 */
export const post = (url, data, showErr = true, config = {}) => {
  return apiResult($axios.$post(url, data, config), showErr, url);
};

/**
 * $axios.$get wrapper
 *
 * @param url
 * @param data
 * @param showErr
 * @param config
 * @return {*|PromiseLike<T>|Promise<T>}
 */
export const get = (url, data, showErr = true, config = {}) => {
  return apiResult($axios.$get(url, config), showErr);
};

/**
 * @desc 初始化数据
 */
export const initTestData = () => {
  return post('url/of/initTestData', {});
};

/**
 * @desc 并发测试合集
 */
export const concurrentTest = () => {
  return post('url/of/concurrent', {});
};

/**
 * @desc 获取任意用户信息
 *
 * @param uid string
 * @returns {Promise<T>} {
  "errno": 200,
  "data": {
    "id": "1",
    "__email": "t",
    "email": "",
    "mobile": "189****344900",
    "nick": "cyb",
    "__pic": "t",
    "pic": "http://localhost/./upload_pic/avatar_1",
    "reg_time": "2019-10-22 15:12:41",
    "num": "97",
    "follow": null, // 当前登录用户是否关注了此用户
    "fensi": "4",
    "guanzhu": "8",
  },
  "status": true,
  "errmsg": "OKAY"
}
 */
export const getAnyUserInfo = (uid) => {
  return post("/api/userinfo/get-userinfo", {
    uid: uid
  });
};

/**
 * @desc 获取当前登录用户信息
 *
 * @returns {Promise<T>} {
  "status": true,
  "data": {
    "id": "1096",
    "username": "1096",
    "__email": "t",
    "email": "",
    "mobile": "189****1234",
    "nick": "1234",
    "__pic": "t",
    "pic": "http://localhost/./upload_pic/avatar_1",
    "status": "1",
  },
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const getMyInfo = () => {
  return post("/api/userinfo/get-my-info", {}, false);
};

/**
 * @desc 文库列表
 *
 * @param pageNo
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": [
    {
      "id": "801",
      "uid": "3000",
      "article_id": "223383.md",
      "abstract": "update content, merge test\n743780\n"
    }
  ],
  "page_html": "<div id=page><a href='?pageNo=1' title='首页'>首页</a><a href='?pageNo=1' title='上一页'>上一页</a><a href='?pageNo=1' title='第1页'>1</a><a href='?pageNo=2' title='第2页' class='cur'>2</a><span>下一页</span><span>尾页</span><span class='pageRemark'>共<b>2</b>页<b>131</b>条数据</span></div>",
  "page_total": 131,
  "current_page": "2",
  "paging_offset": 100,
  "errno": 200,
  "errmsg": "OKAY",
  "stime": 1623916860
}
 */
export const getCatalogList = (pageNo) => {
  return post("/api/article/catalog", {
    pageNo,
  });
};

/**
 * @desc 获取用户目录结构
 *
 * @param uid int
 * @returns {Promise<T>} {
  "__data": "k",
  "data": [
    {
      "id": "1128",
      "name": "aa",
      "path": "aa.md",
      "type": "file",
      "ext": "md",
      "child": [],
      "mtime": "1618541585",
      "time": "1618541585",
    }
  ],
  "status": true,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const getUserArticleList = (uid) => {
  return post("/api/article/showlist", {
    uid: uid
  });
};

/**
 * @desc 获取文章内容
 *
 * @param article_id Number
 * @return {Promise<any>}
 {
   "status": true,
   "data": "",
   "path": "README.md",
   "info": {
     "time": "2021-02-26 22:55:36",
     "updata_time": "2021-02-26 22:55:36",
     "uid": "1067",
     "__notree": "i",
     "notree": {
       "after": {
         "filename": "a8",
         "aid": "1352"
       },
       "before": []
     },
     "tags": {
       "art_artag_classify": [],
       "art_artag": [],
       "art_artag_user": []
     }
   },
   "__version": "k",
   "version": "95a20ee",
   "errno": 200,
   "errmsg": "OKAY"
 }
 */
export const getArticleSrc = (article_id) => {
  return post("/api/article/show", { id: article_id });
};

/**
 * @desc 获取文章的展示内容
 *
 * @param article_id
 * @param hid heading id
 * @param uid user id, 与 hid 配合，用于查询 uid 指定的用户所有的 hid 列表
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": "<p>xxx</p>\n",
  "path": "README.md",
  "info": {
    "time": "2021-04-28 11:14:59",
    "updata_time": "2021-05-04 15:53:51",
    "title": "README",
    "uid": "3000",
    "__notree": "i",
    "notree": {
      "after": {
        "filename": "lzy-error-parse",
        "aid": "86"
      },
      "before": {
        "filename": "lzy-error-parse",
        "aid": "86"
      },
    },
    "tags": {
      "art_artag_classify": [],
      "art_artag": [],
      "art_artag_user": []
    },
    "__matches": "k",
    "matches": [
      {
        "aid": "6078",
        "level": "2",
        "title": "38635",
        "path": "dir/to/file.md"
      }
    ]
  },
  "errno": 200,
  "errmsg": "OKAY",
  "stime": 1620122018
}
 */
export const getArticleContent = (article_id, hid = '', uid = '') => {
  return post("/api/article/show-html", { id: article_id, hid, uid});
};

/**
 * @desc 根据 hid 获取匹配的文章列表
 *
 * @param hid
 * @param uid
 * @returns {Promise<T>}
 * {
  "__data": "k",
  "data": [
    {
      "aid": "475",
      "level": "3",
      "title": "outline",
      "path": "outline.md"
    }
  ],
  "errno": 200,
  "status": true,
  "errmsg": "OKAY",
  "stime": 1629904243
}
 */
export const getHeadingMatches = (hid, uid) => {
  return post("/api/article/get-heading-matches", {hid, uid});
};

/**
 * @desc 根据 path 获取 aid（指定用户下的）
 * @param path
 * @param uid
 * @returns {Promise<T>}
 * {
  "data": {
    "aid": "7021"
  },
  "errno": 200,
  "status": true,
  "errmsg": "OKAY",
  "stime": 1662128224
}
 */
export const getAid = (path, uid) => {
  return post("/api/article/get-aid", {path, uid});
};

/**
 * @desc 重命名文章
 *
 * @param aid
 * @param old_name
 * @param new_name
 * @returns {Promise<T>} {
  "status": true,
  "data": {
    "version": "4436cd7",
    "aid": true
  },
  "errno": 200,
  "errmsg": "OKAY",
  "stime": 1627973917
}
 */
export const renameArticle = (aid, old_name, new_name) => {
  return post("/api/markdown/rename", {
    aid,
    old_name,
    new_name,
  });
};

/**
 * @desc 重命名文件夹
 * @param old_name
 * @param new_name
 * @returns {Promise<T>} {
  "status": true,
  "data": {
    "version": "f66f737",
    "aid": true
  },
  "errno": 200,
  "errmsg": "OKAY",
  "stime": 1624521698
}
 */
export const renameDir = (old_name, new_name) => {
  return post("/api/markdown/rename-dir", {
    old_name,
    new_name,
  });
};

/**
 * @desc 获取当前 git version
 *
 * @returns {Promise<T>} {
  "data": "5c4d5cd",
  "errno": 200,
  "status": true,
  "errmsg": "OKAY",
  "stime": 1627898180
}
 */
export const getVersion = () =>{
  return get("/api/markdown/version");
};

/**
 * @desc 同步仓库外部更新
 *
 * @param uid
 * @return {Promise<T>}
 * {
  "status": true,
  "data": {
    "version": ""
  },
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const updateRepo = (uid) => {
  return post("/api/markdown/repo-update", {uid});
};

/**
 * @desc 同步数据库、es，保持与文件系统一致
 *
 * @param uid
 * @param version 需要重置到的 git version，可选
 * @param vcode 如果传递了 version 参数，则必传此参数
 * @returns {Promise<T>}
 */
export const syncRepo = (uid, version, vcode) => {
  return post("/api/markdown/repo-sync", {uid, version, vcode});
};

/**
 * @desc check file/db/es
 *
 * @param uid
 * @returns {Promise<T>}
 */
export const checkRepo = (uid) => {
  return post("/api/markdown/repo-check", {uid});
};

/**
 * @desc 搜索接口
 *
 * @param keyword String
 * @param uid int
 * @returns {Promise<T>}
 * {
  "data": {
    "tags": [],
    "users": [],
    "article": [
      {
        "_index": "mynote",
        "_type": "article",
        "_id": "1457",
        "_score": 5.2284837,
        "_source": {
           "__id": "t",
          "id": 1457,
          "abstract": "340558",
          "title": "64116"
        },
        "__highlight": "i",
        "highlight": {
          "content.pinyin": [
            "<em>340558</em>"
          ],
          "content": [
            "<em>340558</em>"
          ]
        },
        "_artinfo": {
          "aid": "1457",
          "ccnt": "0",
          "uvcnt": "0",
          "likecnt": "0",
          "time": "2021-03-04 21:06:58",
          "updata_time": "2021-03-04 21:06:58",
          "uid": "1096",
          "nick": "1234",
          "pic": "",
          "reg_time": "2021-02-28 16:30:28"
        }
      }
    ],
    "art_total": 1
  },
  "status": true,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const search = (keyword, uid = 0) => {
  return post("/api/search/index", {s: keyword, uid});
};

/**
 * @desc 搜索指定类型：user/tag/article
 *
 * @param keyword String
 * @param type String user/tag/article
 * @param page_num int 分页数，1-based
 * @param size int 每页大小
 * @param uid int 指定搜索的用户，在 type = article 时可选
 */
export const searchByType = (keyword, type, page_num, size, uid = 0) => {
  return post("/api/search/search", {s: keyword, uid, type, page_num: page_num, size});
};

/**
 * @desc 获取文章评论
 *
 * @param aid
 * @param num
 * @returns {Promise<T>}
 * {
  "status": true,
  "__data": "i",
  "data": [
    {
      "islike_cnt": 1,
      "like_cnt": 1,
      "id": "122",
      "user_id": "1096",
      "comment_id": "0",
      "source_id": "0",
      "floor": "0",
      "nickname": "1234",
      "pic": "",
      "content": "some comment",
      "time": "2021-03-05 13:44:27"
    }
  ],
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const getComment = (aid, num) => {
  return post("/api/comment/show", {aid: aid, num: num});
};

/**
 * @desc 添加评论
 *
 * @param data
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": {
    "id": 124
  },
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const addComment = (data) => {
  return post("/api/comment/add", data);
};

/**
 * @desc 评论点赞
 *
 * @param id
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": 1,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const commentLike = (id) => {
  return post("/api/comment/like", {cid: id});
};

/**
 * 获取文章信息.
 *
 * @param id
 */
export const getArticleInfo = (id) => {
  return post("/api/article/info", {aid: id});
};

/**
 * @desc 文章点赞
 *
 * @param id
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": 1,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const articleLike = (id) => {
  return post("/api/article/like", {aid: id});
};

/**
 * @desc 举报
 *
 * @param id
 * @param type 1文章 2评论
 * @param info
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": 1,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const addReport = (id, type, info) => {
  return post("/api/report/index", {id: id, ip: "127.0.0.1", type: type, info: info});
};

/**
 * @desc 获取标签 by article id
 * @param aid
 */
export const getTags = (aid) => {
  return post("/api/article/getags", {aid: aid});
};

/**
 * @desc 搜索标签（编辑时）
 *
 * @param key
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": [
    {
      "id": "1",
      "tag": "js",
      "type": "1"
    }
  ],
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const searchTag = (key) => {
  return post("/api/article/like_tags", {search_tag: key}, false);
};

/**
 * @desc 新建文章
 *
 * @param data
 */
export const createArticle = (data) => {
  return post("/api/markdown/create", data);
};

/**
 * @desc 新建文章
 *
 * @param path
 * @param content
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": {
    "version": "1e4ac62",
    "aid": 1402
  },
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const createArticle2 = (path, content) => {
  return post("/api/markdown/create", {path, content});
};

/**
 * @desc 保存文章
 *
 * @param data
 */
export const updateArticle = (data) => {
  return post("/api/markdown/update", data);
};

/**
 * @desc 保存文章
 *
 * @param aid
 * @param path
 * @param content
 * @param version
 * @param artag_classify_ids
 * @param artag_ids
 * @param artag_users
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": {
    "version": "10ca774",
    "aid": 1837
  },
  "errno": 200,
  "errmsg": "OKAY",
  "__failedVersion": "k",
  "failedVersion": "a4d8114" // merge 失败时会返回
}
 */
export const updateArticle2 = (aid, path, content, version, artag_classify_ids, artag_ids, artag_users) => {
  return post("/api/markdown/update", {aid, path, content, version, artag_classify_ids, artag_ids, artag_users});
};

/**
 * 获取导入文章 token/code
 *
 * @returns {Promise<T>}
 * {
  "data": {
    "token": "60a5f0a5-d3b0-b1a5-596e-3e48170d9202",
    "code": ""
  },
  "errno": 200,
  "status": true,
  "errmsg": "OKAY"
}
 */
export const getImportArtCode = () => {
  return post('/api/markdown/get-import-art-code');
};

/**
 * @desc 导入文章
 *
 * @param uid
 * @param token
 * @param path
 * @param content
 * @param import_src 导入源，如掘金
 * @param import_id 导入源中的文章 id
 * @returns {Promise<T>}
 {
   "status": true,
   "data": {
     "version": "8e6602e",
     "__aid": "k",
     "aid": 3415
   },
   "errno": 200,
   "errmsg": "OKAY"
 }
 */
export const importArt = (uid, token, path, content, import_src, import_id) => {
  return post('/api/markdown/import', {uid, token, path, content, import_src, import_id});
};

/**
 * @desc 从外网抓取图片
 *
 * @param url
 * @returns {Promise<T>}
 * {
  "errno": 200,
  "errmsg": "OK",
  "fetch_status": "200",
  "ext": ".jpeg",
  "url": "http://localhost/__pic/98/4eab9b4b85a2ee1e0e60ae57124f2a.jpeg"
}
 */
export const fetchPic = (url) => {
  return post("/api/markdown/fetchpic", {url: url});
};

/**
 * 上传图片.
 *
 * @param blob
 * @param success
 */
export const uploadImg = (blob, success) => {
  const data = new FormData();
  data.append('editormd-image-file', blob);
  return new Promise((resolve, reject) => {
    $axios.$post('/api/markdown/uploadpic', data, {
      requestType: UPLOAD_TPYE
    }).then((res) => {
        if (res.success === 1) {
          success && success(res.url);
          resolve(res.url);
        }
      }, (rej) => {
        reject(rej);
      });
  });
};

/**
 * 关注用户.
 *
 * @param fuid
 * @param status, 0: 取消关注，1：关注
 */
export const followUser = (fuid, status) => {
  return post("/api/userinfo/follow", {fuid: fuid, status: status ? 1 : 0});
}

/**
 * @desc 获取我的粉丝和我的关注
 *
 * @returns {Promise<T>}
 * {
  "data": {
    "__guanzhu": "k",
    "guanzhu": [
      {
        "id": "2003",
        "__email": "t",
        "email": "",
        "mobile": "115****9799",
        "nick": "9799",
        "pic": null,
        "reg_time": "2021-03-14 11:22:01",
        "num": "1",
        "follow": "2003",
        "fensi": "1",
        "guanzhu": "0"
      }
    ],
    "__fensi": "k",
    "fensi": [
      {
        "id": "2005",
        "__email": "t",
        "email": "",
        "mobile": "141****3282",
        "nick": "3282",
        "pic": null,
        "reg_time": "2021-03-14 11:34:03",
        "num": "1",
        "__follow": "t",
        "follow": null,
        "fensi": "0",
        "guanzhu": "0"
      }
    ]
  },
  "errno": 200,
  "status": true,
  "errmsg": "OKAY"
}
 */
export const getFollow = () => {
  return get("/api/userinfo/getfollow");
}

/**
 * 获取 gogs site url
 */
export const getGogsURL = () => {
  return get("/api/userinfo/getgogsurl");
}

/**
 * @desc 发送短信验证码
 *
 * @param tel
 * @param vcode
 * @param mod_type
 * @returns {Promise<T>}
 */
export const sendTelCode = (tel, vcode, mod_type) => {
  return post('/api/user/telcode', {tel, vcode, mod_type, type: 'web'});
};

/**
 * @desc 发送邮箱验证码
 *
 * @param email
 * @param vcode
 * @returns {Promise<T>}
 */
export const sendEmailCode = (email, vcode) => {
  return post('/api/user/emailcode', {email, vcode, type: 'web'});
};

/**
 * 修改手机号，第一步，验证原始手机号 + 验证码
 *
 * @param tel
 * @param code
 * @return {Promise<T>}
 * {
  "errno": 200,
  "status": true,
  "errmsg": "OKAY"
}
 */
export const changeTel1 = (tel, code) => {
  return post('/api/userinfo/change-tel1', {tel, code});
};

/**
 * @desc 忘记密码
 *
 * @param tel
 * @param code
 * @param mi
 * @returns {Promise<T>}
 */
export const forgetPasswd = (tel, code, mi) => {
  return post('/api/userinfo/forget', {tel, code, mi});
};

/**
 * @desc 修改手机号，第二步，重新绑定手机号码
 *
 * @param tel
 * @param code
 * @returns {Promise<T>}
 */
export const changeTel2 = (tel, code) => {
  return post('/api/userinfo/change-tel2', {tel, code});
};

/**
 * 设置 gogs 用户密码
 */
export const setGogsPassword = (password) => {
  return post("/api/user/gogspass", {"gogs_mi": password});
}

/**
 * 设置昵称
 *
 * @param nick
 * @returns {Promise<T>}
 */
export const changeNick = (nick) => {
  return post("/api/userinfo/nick", {nick});
};

/**
 * @desc 绑定邮箱
 *
 * @param email
 * @param code
 * @returns {Promise<T>}
 */
export const bindEmail = (email, code) => {
  return post("/api/userinfo/email", {email, code});
};

/**
 * @desc 解除邮箱绑定
 *
 * @returns {Promise<T>}
 */
export const unbindEmail = () => {
  return post("/api/userinfo/unbind", {});
};

/**
 * @desc 删除文章
 *
 * @param aids Array|String
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": {
    "version": "167644e",
    "aid": true
  },
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const deleteArticle = (aids) => {
  if (!Array.isArray(aids)) {
    aids = [aids];
  }
  return post("/api/markdown/del", {aids});
};

/**
 * 获取首页推荐作者.
 *
 * @param start
 * @returns {Promise<any>}
 */
export const authorChange = (start) => {
  return post('/api/userinfo/change', {s: start});
};

/**
 * 获取验证码图片.
 *
 * @returns {Promise<void>}
 */
export const getCodeImg = () => {
  // 返回 blob 对象，不要用 apiResult
  // uTODO: 错误处理
  return new Promise(function (resolve) {
    $axios.$get('/api/vcode/tmp', {responseType: 'blob'}).then(res => {
      let reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = () => resolve(reader.result);
    });
  });
};

/**
 * 获取首页轮播图
 *
 * @return {Promise<T>}
 */
export const lunbotu = () => {
  return get('/api/article/lunbotu', false);
};

/**
 * @desc 手机账号注册
 *
 * @param nick
 * @param tel
 * @param code
 * @param password
 * @returns {Promise<any>}
 {
   "status": true,
   "data": {
     "id": 1071
   },
   "errno": 200,
   "errmsg": "OKAY"
 }
 */
export const telregister = (nick, tel, code, password) => {
  return post('/api/user/telregister', {nick, tel, code, password});
};

/**
 * @desc 登录
 *
 * @param tel 手机号
 * @param password 密码
 * @returns {Promise<any>}
 {
   "status": true,
   "data": {
     "id": "1096",
     "username": "1096",
    "__email": "t",
    "email": "",
     "mobile": "189****1234",
     "nick": "1234",
     "__pic": "t",
     "pic": "",
     "status": "1",
   },
   "errno": 200,
   "errmsg": "OKAY"
 }
 */
export const login = (tel, password) => {
  return post('/api/user/login', {
    name: tel,
    password
  });
};

/**
 * @desc 退出登录
 */
export const logout = () => {
  return {data: {}};
};

/**
 * 获取推荐作者
 *
 * @param start 分页数，1 based
 * @param sort ASC/DESC
 * @returns {Promise<any>}
 * {
  "show_more": true,
  "status": true,
  "data": [
    {
      "id": "1001",
      "__email": "t",
      "email": "",
      "mobile": "139****2761",
      "nick": "eson",
      "__pic": "t",
      "pic": "http://uninote.com.cn/./upload_pic/avatar_1",
      "reg_time": "2018-10-09 17:50:05",
      "num": "0",
      "follow": null,
      "fensi": "3",
      "guanzhu": "0",
      "art": [
        {
          "aid": "96",
          "article_id": "3.6.md"
        }
      ]
    }
  ],
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const allRecommendUser = (start, sort = 'ASC') => {
  return post('/api/userinfo/allrecommend', {start, sort});
};

/**
 * @desc 上传头像
 *
 * @param base64
 * @returns {Promise<T>}
 * {
  "data": "http://a.mynote.my/upload_pic/avatar_1229",
  "status": true,
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const changeAvatar = (base64) => {
  return post('api/userinfo/pic', {base64});
};

/**
 * @desc 获取首页推荐文章
 *
 * @param num
 * @returns {Promise<T>}
 * {
  "status": true,
  "data": [
    {
      "aid": "13",
      "ccnt": "2",
      "uvcnt": "1",
      "likecnt": "0",
      "__thum": "t",
      "thum": "http://localhost:3000/__pic/00/36ee793e0dab817dcdc5fd8edd4034.png",
      "__abstract": "t",
      "abstract": "介绍本文将介绍我...",
      "time": "2019-10-22 15:38:59",
      "updata_time": "2021-02-12 18:04:48",
      "title": "python爬虫之入坑和出坑",
      "uid": "1",
      "nick": "cyb",
      "pic": "http://localhost/./upload_pic/avatar_1",
      "reg_time": "2019-10-22 15:12:41",
      "art_url": "/book/item?id=13"
    }
  ],
  "errno": 200,
  "errmsg": "OKAY"
}
 */
export const getArticleRecommend = (num) => {
  return post('/api/article/recommend', {num}, false);
};

/**
 * @desc 设置文章访问授权
 *
 * @param is_public 文章的默认读取权限（对所有用户）
 * @returns
 */
export const setSettings = (is_public) => {
  return post('api/userinfo/set-grant-settings', {is_public});
};
