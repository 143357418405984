import * as apis from '~/utils/api';
import { GET_ROLLING_BANNER, RECORD_SERVER_TIME } from "./mutation-types";

export const state = () => ({
  rollingBanner: null
});


export const mutations = {
  [GET_ROLLING_BANNER](state, data) {
    state.rollingBanner = data;
  },
  [RECORD_SERVER_TIME] (state, serverTime) {
    state.serverTime = serverTime;
  },
};


export const actions = {
  /**
   * 获取首页轮播图.
   *
   * @param commit
   */
  getRollingBanner({ commit }) {
    apis.lunbotu().then(res => {
      commit(GET_ROLLING_BANNER, res.data);
    });
  },

  setServerTime({commit}, data) {
    commit(RECORD_SERVER_TIME, data);
  },
};
