<style lang="stylus" scoped>
  .content-container {
    position relative
    margin 0 auto
    width 100%
    max-width 960px
    top 5rem
    &::after {
      display table
      content ""
      clear both
    }
  }
  @media (max-width 980px) {
    .content-container {
      padding 0
      top 48px
    }
  }
</style>

<template>
  <div class="template-container">
    <v-header></v-header>
    <nuxt/>
    <v-login></v-login>
  </div>
</template>

<script>
  import VHeader from '~/components/home/header'
  import VLogin from '~/components/login'

  export default {
    components: {
      VHeader,
      VLogin,
    },

    /**
     * 在页面加载前先获取 localStorage 中的用户信息
     */
    mounted() {
      this.$store.dispatch('auth/getUserInfo');

      // eruda debugger, using "?debugger=1" to start and "?debugger=0" to destroy
      const DEBUGGER_KEY = 'debugger';
      const url = new URL(location.href);
      const value = url.searchParams.get(DEBUGGER_KEY);
      if (value !== null) {
        localStorage.setItem(DEBUGGER_KEY, value);
      }

      if (localStorage.getItem(DEBUGGER_KEY) === "1") {
        const script = document.createElement('script');
        script.src = "//cdn.jsdelivr.net/npm/eruda";
        document.body.appendChild(script);
        script.onload = function () {
          eruda.init();
        }
      }
    }
  }
</script>

