<template>
  <div class="content-container">
    <v-header></v-header>

    <el-container>
      <el-container>
        <el-aside width="200px" class="user-center-left" v-if="show_menu">
          <div class="aside-title">
            <nuxt-link to="/">
              <img src="~/assets/img/logo.png" alt="Uninote" class="logo-img">
            </nuxt-link>
            <h1>用户中心</h1>
          </div>
          <ul class="left-menu">
            <li :class="this.$route.name == 'user-setting' ? 'have-bgcolor' : ''" @click="hideMenu">
              <nuxt-link to="/user/setting">
                <i class="iconfont ic-settings-basic"></i>
                <span>基础设置</span>
              </nuxt-link>
            </li>
            <li :class="this.$route.name == 'user-fans' ? 'have-bgcolor' : ''" @click="hideMenu">
              <nuxt-link to="/user/fans">
                <i class="iconfont ic-friends"></i>
                <span>互相关注</span>
              </nuxt-link>
            </li>
            <!--
            <li :class="this.$route.name == 'user-message' ? 'have-bgcolor' : ''" @click="hideMenu">
              <nuxt-link to="/user/message">
                <i class="iconfont ic-chats"></i>
                <span>消息通知</span>
              </nuxt-link>
            </li>
            -->
            <li :class="this.$route.name == 'user-gogs' ? 'have-bgcolor' : ''" @click="hideMenu">
              <nuxt-link to="/user/gogs">
                <i class="iconfont ic-settings-account"></i>
                <span>gogs账号</span>
              </nuxt-link>
            </li>
          </ul>
        </el-aside>
        <el-main>
          <nuxt/>
        </el-main>
      </el-container>
    </el-container>

    <!-- 遮罩层 -->
    <div class="user-center-modal" v-if="show_menu_modal" @click="hideMenu"></div>
    <!-- 显示菜单按钮 -->
    <button class="show-menu" @click="showMenu">
      <i class="el-icon-menu"></i>
    </button>
    <v-login></v-login>
  </div>
</template>

<script>
  import VHeader from '~/components/home/header'
  import VLogin from '~/components/login'
  import 'element-ui/lib/theme-chalk/display.css';


  export default {
    components: {
      VHeader, 
      VLogin,
    },
    data() {
      return {
        show_menu_modal: false,
        show_menu: false,
      }
    },

    async created() {
      this.$store.dispatch('auth/getUserInfo');
    },

    mounted() {
      if (process.client && document.body.clientWidth <= 650) {
        this.show_menu = false;
      } else {
        this.show_menu = true;
      }
    },

    /**
     * 方法
     */
    methods: {
      /**
       * 展示菜单
       */
      showMenu() {
        this.show_menu = true;
        this.show_menu_modal = true;
      },

      /**
       * 隐藏菜单
       */
      hideMenu() {
        if (document.body.clientWidth <= 650) {
          this.show_menu = false;
        }
        this.show_menu_modal = false;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .content-container {
    max-width: 960px;
    margin: 0 auto;
    margin-top: 75px;
    min-height: 100%;
  }
  .el-aside {
    background: #FFF;
    margin-right: 15px;
  }
  .left-menu li {
    border-bottom: 1px solid #f4f5f6;

    &:last-child {
      border: none;
    }
  }
  .left-menu li.active a, .left-menu li:hover a {
    background-color: #EEEDEE;
    border-radius: 4px;
    color: #138E57;
  }
  .left-menu a {
    padding: 10px 15px;
    font-size: 15px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .left-menu a span {
    font-size: 16px;
  }
  .left-menu a i {
    padding: 7px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
    display: inline-block;
    background: #969596;
    color: #FFF;
  }
  .el-main {
    background: #FFF;
    border-radius: border_radius
  }
  .have-bgcolor {
    background: #EEEDEE;
  }
  .show-menu {
    display: none;
  }

  .aside-title {
    text-align: center;
    display: none;
    img {
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .user-center-left
    border-radius border_radius

  /* 响应式 */
  @media (max-width 650px) {
    .user-center-left {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 1000;
      width: 85% !important;
    }

    .user-center-modal {
      width: 100%;
      height: 100%;
      background: #333;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      opacity: 0.7;
    }
    .show-menu {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 25px;
      color: #FFF;
      background: #128E56;
      border: none;
      outline: none;
      border-radius: 50%;
      position: fixed;
      right: 30px;
      bottom: 30px;
    }
    .aside-title {
      display: block;
    }
    .el-container {
      padding: 0 10px;
    }
  }
</style>
