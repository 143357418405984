/**
 * @Description: 图片预览指令 直接在img标签上添加 v-p-img 即可实现点击图片时候对图片进行预览
 * @Author: mahui
 * @Date: 2021/5/12 1:02 AM
 * <img src=""v-p-img >
 */

import Vue from "vue"
import preImg from '~/utils/previewImg';

const preViewImg = Vue.directive("p-img",{
  inserted:function(el,binding){
    el.style.cursor = 'pointer';
    el.title = '点击可查看大图';
    // 放大倍数
    let allowScale = (binding.value && binding.value.scale) || 1;
    let allowOpacity = (binding.value && binding.value.opacity) || 0.5;
    preImg(el, allowScale, allowOpacity)
  }
})

export default preViewImg

