<template>
  <!-- 空白布局 -->
  <div class="layout-content-wrap">
    <nuxt/>
    <v-login></v-login>
  </div>
</template>

<script>
  import VLogin from '~/components/login'
  import { mapState } from 'vuex';

  export default {
    name: "blank",
    components: { VLogin },
    mounted() {
      this.$store.dispatch('auth/getUserInfo');
    },
    computed: {
      ...mapState('auth', [
        'isLogin'
      ])
    },
  }
</script>

<style>
  html, body, #__nuxt, #__layout, .layout-content-wrap {
    height: 100%;
  }
</style>
