<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .search-from {
    @extend .gs_center
    flex auto
    max-width 25rem
    border 1px solid hsla(0, 0%, 59%, .2)
    padding-left 8px
    border-radius border_radius_btn
    background-color rgba(227, 231, 236, .2)
    .search-input {
      border none
      flex auto
      width 30px
      min-width 10px
      box-shadow none
      outline none
      font-size 1.1rem
      color normal_text_color
      background-color transparent
    }
    .search-icon {
      padding-right 0.4rem
      cursor pointer
      background url('../assets/img/search.svg') center no-repeat
      width 26px
      height 26px
    }
  }
  .search-from.focus {
    background white
    border 1px solid base_color
    .search-icon {
      background url('../assets/img/active-search.svg') center no-repeat
    }
  }

  @media (max-width 380px) {
    .search-from {
      .search-input {
      }
    }
  }
</style>

<template>
  <div
    class="search-from"
    :class="{
      'focus': focused
    }">
    <input
      class="search-input"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :value="value"
      @keyup.enter="search"
      @change="handleChange"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur">
    <span v-show="type === 'search'" class="search-icon" alt="搜索" @click="search"></span>
  </div>
</template>

<script>
  export default {
    name: 'VInput',
    data () {
      return {
        focused: false,
      }
    },
    props: {
      type: {
        type: String,
        default: 'search'
      },
      placeholder: {
        type: String,
        default: '搜索作者/标签/文章'
      },
      maxlength: {
        type: [Number, String],
        default: 20
      },
      value: {
        type: String,
        default: ''
      }
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeydown)
    },
    methods: {
      search () {
        this.$emit('search', this.value)
      },
      handleChange (event) {
        this.$emit('change', event.target.value)
      },
      handleInput (event) {
        this.$emit('input', event.target.value)
      },
      handleFocus (event) {
        this.focused = true
        this.$el.querySelector("input").select(); // 全选
        this.$emit('focus', event)
      },
      handleBlur (event) {
        this.focused = false
        this.$emit('blur', event)
      },
      handleKeydown(event) {
        var c = String.fromCharCode(event.keyCode);
        if (c === 'F' && event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
          this.$el.querySelector("input").focus();
          event.preventDefault();
        }
      }
    }
  }
</script>
