import * as apis from '~/utils/api';
import { SUCCESS } from '~/utils/status-code';
import {RECORD_USERINFO, LOGOUT, CHANGE_NICK, BIND_EMAIL, UN_BIND, BIND_TEL, UPLOAD_IMG, SHOW_LOGIN} from "./mutation-types";
import { errorMsg, successMsg } from '~/utils/tools';
import Cookies from 'js-cookie';
import * as scode from '~/utils/status-code';

export const state = () => ({
  userInfoInited: false,
  userInfo: {},
  isLogin: false,
  showLogin: false,
});

export const mutations = {
  /**
   * 记录用户信息.
   *
   * @param state
   * @param info
   */
  [RECORD_USERINFO] (state, info) {
    if (state.isLogin) {
      return;
    }
    if (!info || !('id' in info)) {
      return;
    }
    state.userInfoInited = true;
    state.userInfo = info;
    state.userInfo.uid = info.id;
    state.isLogin = true;
  },

  [LOGOUT](state) {
    state.isLogin = false;
    state.userInfo = {};
    Cookies.remove('PHPSESSID');
  },

  /**
   * 更新 vuex 中的用户昵称和 localStorage 中的用户昵称.
   *
   * @param state
   * @param nick
   */
  [CHANGE_NICK](state, nick) {
    if (state.userInfo != {}) {
      state.userInfo.nick = nick;
    }
  },
  /**
   * 更新 vuex 和 localStorage 中的用户昵称.
   *
   * @param state
   * @param email
   */
  [BIND_EMAIL](state, email) {
    state.userInfo.email = email;
  },
  /**
   * 解除绑定手机或邮箱.
   *
   * @param state
   */
  [UN_BIND] (state) {
    state.userInfo.email = "";
  },
  /**
   * 绑定电话.
   *
   * @param state
   * @param tel
   */
  [BIND_TEL] (state, tel) {
      state.userInfo.mobile = tel;
  },
  /**
   * 上传头像.
   *
   * @param state
   * @param img_url
   */
  [UPLOAD_IMG] (state, img_url) {
    state.userInfo.pic = img_url + "?time=" + (new Date()).valueOf();
  },

  /**
   * 展示登录弹窗
   *
   * @param state
   * @param val
   */
  [SHOW_LOGIN] (state, val) {
    state.showLogin = val
  }
};

export const actions = {
  /**
   * 退出登录
   * @param commit
   */
  logout({ commit }) {
    commit(LOGOUT);
  },

  /**
   * 获取当前登录用户信息存入 vuex 中.
   *
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  getUserInfo({ commit, state }) {
    if (!state.userInfoInited) {
      return apis.getMyInfo().then(res => {
        commit(RECORD_USERINFO, res.data);
      }).catch(res => {
        if (res.errno !== scode.USER_NOT_LOGIN.code) {
          console.log(res);
        }
      });
    }
  },

  /**
   * 获取所有作者.
   *
   * @param commit
   * @param data
   * @returns {Promise<any>}
   */
  allRecommendUser({ commit }, data) {
    return apis.allRecommendUser(data.start, data.sort);
  },

  /**
   * 修改昵称.
   *
   * @param commit
   * @param nick
   * @returns {Promise<void>}
   */
  async changeNick({ commit }, nick) {
    let res =  await apis.changeNick(nick);
    if (res.errno == SUCCESS.code) {
      // 更新 vuex上的用户昵称以及 localStorage 中的用户昵称
      successMsg("昵称修改成功");
      commit(CHANGE_NICK, nick);
    }
  },

  /**
   * 绑定邮箱地址.
   *
   * @param commit
   * @param data {{email: string, code: string}}
   * @returns {Promise<boolean>}
   */
  async bindEmail({ commit }, data) {
    let res = await apis.bindEmail(data.email, data.code);
    if (res.errno != SUCCESS.code) {
      errorMsg(res.errmsg);
      return false;
    }
    successMsg("邮箱绑定成功");
    commit(BIND_EMAIL, data.email);
    return true;
  },

  /**
   * 修改手机号，第二步，重新绑定手机号码
   * @param commit
   * @param data {{tel: string, code: string}}
   * @returns {Promise<boolean>}
   */
  async changeTel2({ commit }, data) {
    let res = apis.changeTel2(data.tel, data.code);
    if (res.errno != SUCCESS.code) {
      errorMsg(res.errmsg);
      return false;
    }

    commit(BIND_TEL, data.tel);
    return true;
  },

  /**
   * 解除绑定邮箱.
   *
   * @param commit
   * @returns {Promise<void>}
   */
  async unbindEmail({ commit }) {
    let res = await apis.unbindEmail();
    if (res.errno != SUCCESS.code) {
      errorMsg(res.errmsg);
      return false;
    }

    successMsg("邮箱解除绑定成功！");
    commit(UN_BIND);
    return true;
  },

  /**
   * 上传头像
   * @param commit
   * @param base64
   * @returns {Promise<boolean>}
   */
  async changeAvatar({ commit }, base64) {
    let res = await apis.changeAvatar(base64);

    if (res.errno != SUCCESS.code) {
      errorMsg("头像设置失败");
      return false;
    }

    commit(UPLOAD_IMG, res.data);
    successMsg("头像上传成功");
    return true;
  },
};
