import {SET_ZOOM} from './mutation-types'

export const state = () => ({
  isEditing: false,
  isZoom: false,
});

export const mutations = {
  [SET_ZOOM](state) {
    state.isZoom = !state.isZoom
  },
};

export const actions = {
};
