export const ARTICLE_RECOMMEND = 'ARTICLE_RECOMMEND';   // 获取推荐文章
export const RECORD_USERINFO = 'RECORD_USERINFO';       // 记录用户信息
export const RECOMMEND_OFFSET = 'RECOMMEND_OFFSET';     // 推荐文章偏移量
export const HAVE_NEW_RECOMMEND = 'HAVE_NEW_RECOMMEND'; // 是否还有新的推荐文章
export const GET_ROLLING_BANNER = 'GET_ROLLING_BANNER'; // 获取轮播图
export const LOGOUT = 'LOGOUT';                         // 退出登录
export const CHANGE_NICK = 'CHANGE_NICK';               // 修改用户昵称
export const BIND_EMAIL = 'BIND_EMAIL';                 // 绑定邮箱
export const UN_BIND = 'UN_BIND';                       // 解除绑定
export const BIND_TEL = 'BIND_TEL';                     // 绑定手机号
export const UPLOAD_IMG = 'UPLOAD_IMG';                 // 上传头像
export const RECORD_SERVER_TIME = 'RECORD_SERVER_TIME'; // 记录服务器时间
export const SHOW_LOGIN = 'SHOW_LOGIN';                 // 显示登录弹窗
export const SET_ZOOM = 'SET_ZOOM';                     // 设置最大化
