<template>
  <div class="main">
    <v-login/>
    <el-row style="height: 600px;" class="content-box">
      <el-col :span="12" class="left" style="height: 100%">
        <div style="text-align: center; height: 80%; margin-top:60px" class="left-content">
          <nuxt-link to="/">
            <img src="~/assets/img/big-icon.png" alt="首页" style="display: inline-block; margin-top: 154px;"/>
          </nuxt-link>
          <h2 class="title">Uninote</h2>
        </div>
      </el-col>
      <el-col :span="12" class="right">
        <nuxt/>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import VLogin from '~/components/login'

  export default {
    components: {
      VLogin,
    },

    /**
     * 在页面加载前先获取 localStorage 中的用户信息
     */
    created() {
      this.$store.dispatch('auth/getUserInfo');
    }
  }
</script>

<style lang="stylus" scoped>
  @import "~assets/stylus/variable.styl"

  html, body, #__nuxt, #__layout
    height 100%

  .main {
    height: 100%;
    display: flex;
    align-items center;
    justify-content: center;
    background url("~assets/img/login_bg.png")
    background-size cover

    .content-box {
      width: 870px;
      height 600px;
      background url("~assets/img/login_box_bg.png") center no-repeat
      flex: none;

      .title {
        text-align center
        font-size 28px
        font-weight bold
        color: base_color
      }

      .left-content {
        border-right: solid 1px border_color_lv3;
        margin-left: 81px;
      }
    }
  }

  @media (max-width: 735px) {
    .main {
      .content-box {
        width: 300px;

        .left {
          display: none;
        }

        .right {
          width: 100%
        }
      }
    }
  }
</style>
