<style lang="stylus" scoped>
  /* 导入全局样式变量 */
  @import '~assets/stylus/variable.styl'

  .drop-down {
    .dd-panel {
      display flex
      .dd-item {
        @extend .gs_center
        color header_text_color
        font-size 16px
        cursor pointer

        a {
          width 100%; /* 增大可点击宽高 */
          height 100%;
          text-align center;
          &:hover {
            color base_color
          }
        }
      }
    }

    .nuxt-link-exact-active {
      color base_color
    }
  }

  /* 只有 PC 桌面才用此样式 */
  @media (min-width 980px) {
    .nuxt-link-exact-active {
      border-bottom 2px solid base_color
    }
    .drop-down {
      .dd-btn {
        display none
      }
      .dd-item {
        padding 0 15px
        a {
          padding 15px 0px;
        }
      }
    }
  }

  @media (max-width 980px) {
    .drop-down {
      .dd-btn {
        @extend .gs_center
        height 4rem
        font-size 1.33rem
        color base_color
        border none
        span {
          margin-right .5rem
        }
      }

      .dd-panel {
        position absolute
        left -15px;
        display none
        background-color color_white
        box-shadow 0 1px 2px 0 rgba(0, 0, 0, .1)
        border 1px solid hsla(217, 5%, 71%, .45)
        border-radius 4px
        font-size 1.2rem
        width calc(100% + 30px)

        .dd-item {
          a {
            padding 15px 0;
          }
        }

        &.show {
          display block;
        }
      }
    }
  }
</style>

<template>
  <div class="drop-down" v-clickoutside="hide">
    <div class="dd-btn" @click="handleMenuClick">
      <span>{{ title }}</span>
      <v-icon name="arrow-down-b"></v-icon>
    </div>
    <ul
      id="head-menu"
      class="dd-panel"
      :class="{
        'show': show
      }">
      <li v-for="(item, index) in items" :id="item.title" :key="index" class="dd-item" @click="handleItemClick(item.title)">
        <nuxt-link :to="item.name">
          {{ item.title }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import VIcon from '~/components/icon';
  import Clickoutside from '~/utils/dom/clickoutside'
  import {mapState} from 'vuex'

  export default {
    name: 'VDropdown',
    components: {
      VIcon
    },
    data() {
      return {
        show: false,
        title: '首页',
        items: [
          {name: '/', title: '首页'},
          {name: '/catalog', title: '文库'},
          {name: '/author', title: '作者'},
        ]
      }
    },
    computed: {
      ...mapState({
      })
    },
    created() {
      this.updateTitle();
    },
    methods: {
      handleMenuClick() {
        this.show = !this.show
      },
      handleItemClick(title) {
        this.show = false;
        this.title = title;
      },
      hide() {
        this.show = false
      },
      updateTitle() {
        let path = this.$route.path;
        this.title = "首页";
        let items = [].concat(this.items);
        items.shift(); // 首页作为默认值，从数组中移除，避免误匹配
        items.some((item, i, arr) => {
          if (path.startsWith(item.name)) {
            this.title = item.title;
            return true;
          }
        });
      }
    },
    directives: {
      Clickoutside
    },
    watch: {
      $route() {
        this.updateTitle();
      }
    }
  }
</script>
