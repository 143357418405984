/**
 * @Description: 图片预览
 * @Author: mahui
 * @Date: 2021/5/12 1:53 AM
 */
export default function preImg(el, scale = 1, opacity = 0.5) {
  const allowOpacity = opacity;
  // 图片
  const img = document.createElement("img");
  // 这个查看图片包含框
  const divBox = document.createElement("div");
  const closeBox = document.createElement("span");
  const imgWrapper = document.createElement("div");

  // 查看大图的关闭按钮
  closeBox.innerText = "x";
  closeBox.className = "close";
  imgWrapper.appendChild(img);
  divBox.appendChild(closeBox);
  divBox.appendChild(imgWrapper);
  divBox.style.display = "none";
  const currentWidth = el.clientWidth;
  const imageWidth = el.naturalWidth;
  if (imageWidth > currentWidth) {
    el.style.cursor = "pointer";
    document.getElementById("__nuxt").appendChild(divBox);
    el.addEventListener("click", function () {
      img.src = el.src;
      const html = document.getElementsByTagName("html")[0];
      html.setAttribute("style", "overflow: hidden;height: 100vh");
      divBox.style.cssText = `display: flex;
            position: fixed;
            top: 0;
            bottom: 0;left: 0;
            right: 0;
            background: rgba(0,0,0,${allowOpacity});
            height: 100vh;
            overflow: scroll;
            align-items: center;
            justify-content: center;
            z-index: 9999;`;
      // 设置图片居中
      imgWrapper.style.cssText = `
             position: relative;
             border-radius: 4px;
             overflow: scroll;
             max-width:90%;
             max-height:90%;
             z-index: 1;`;
      // 设置关闭样式
      closeBox.style.cssText = `border: 1px solid #ccc;
            position: absolute;
            top: 30px;
            right: 30px;
            border-radius: 50%;
            display: inline-block;
            height: 22px;
            width: 22px;
            text-align: center;
            font-size: 16px;
            color: #ffffff;
            z-index: 2;`;
    });
    // 点击关闭按钮隐藏查看大图
    closeBox.addEventListener("click", function () {
      divBox.style.display = "none";
      const html = document.getElementsByTagName("html")[0];
      html.setAttribute("style", "height: auto");
    });
    divBox.addEventListener("click", function () {
      divBox.style.display = "none";
      const html = document.getElementsByTagName("html")[0];
      html.setAttribute("style", "height: auto");
    });
  }
};
