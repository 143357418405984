import {ARTICLE_RECOMMEND, RECOMMEND_OFFSET, HAVE_NEW_RECOMMEND} from "./mutation-types";
import * as apis from '~/utils/api';

export const state = () => ({
  recommendList: [],
  haveNew: true,
  offset: 0
});

export const mutations = {
  [ARTICLE_RECOMMEND] (state, info) {
    if (!info) return;
    state.recommendList = state.recommendList.concat(info);
  },
  [RECOMMEND_OFFSET] (state, offset) {
    state.offset += offset;
  },
  [HAVE_NEW_RECOMMEND] (state, haveNew) {
    state.haveNew = haveNew;
  }
};

export const actions = {
  /**
   * 获取首页推荐文章.
   *
   * @param commit
   * @param num
   * @returns {Promise<void>}
   */
  async getArticleRecommend({ commit, state }) {
    if (! state.haveNew) return;

    await apis.getArticleRecommend(state.offset).then(res => {
      commit(ARTICLE_RECOMMEND, res.data);
      commit(RECOMMEND_OFFSET, 20);
      if (!res.data || res.data.length < 20) {
        commit(HAVE_NEW_RECOMMEND, false);
      }
    }).catch(err => {
      console.log(err);
    })
  },

  /**
   * 获取索引页面文章列表.
   *
   * @param commit
   * @param page
   */
  getCatalog({ commit }, page) {
    return apis.getCatalogList(page);
  }
};
